import React, { useEffect, useState } from 'react'
import { columnProps } from '../../Prompts/Functions'
import { useNavigate } from 'react-router-dom'
import { prefetchQuery, useCQuery } from '../../../Services/QueryCache'
import { Button, Flex, Spinner, Text, DateSelect } from '../../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { getProcessState } from './Functions'
import { useCapsule } from '../../../State/Functions'
import { cap } from '../../../State'

export function GigiList () {
  const [selectedDate, setSelectedDate] = useCapsule(cap.selectedDateGigiList)
  const { data: conversations = [], isSuccess } = useCQuery(['conversations', selectedDate])
  const [conversationsTable, setConversationsTable] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    isSuccess &&
    setConversationsTable(conversations.map((r) => ({
      ...r,
      travellerSearch: [r.traveller?.pid, r.traveller?.email, r.traveller?.phone, r.traveller?.name + ' ' + r.traveller?.surname]?.filter((e) => e).join(' - '),
      createdAt: new Date(r.createdAt).toLocaleString(),
      processState: getProcessState(r)
    })))
  }, [conversations])

  const BtnActionProc = ({ processState, id, status }) => (
    <Flex jb row>
      {(status === 'paused' || processState?.value === 5 || processState?.value === 6) && <Text value='⚠️' style={{ marginRight: 10 }} />}
      <Button round icon='beauty' onClick={() => navigate('/operatore/' + id)} onHover={() => prefetchQuery(['conversation', id?.toString()])} />
    </Flex>
  )

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw>
      <DateSelect date={[selectedDate, setSelectedDate]} />
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' sortField='createdAt' sortOrder={-1} className='TableSignatures'
        value={conversationsTable} emptyMessage='Nessuna conversazione trovata' responsiveLayout='scroll' paginator rows={10}
      >
        <Column field='travellerSearch' {...columnProps('Richiedente')} />
        <Column field='conversationIdMidOffice' {...columnProps('AIC')} />
        <Column field='processState.label' {...columnProps('Stato')} />
        <Column field='createdAt' {...columnProps('Data creazione')} />
        <Column style={{ border: 'none', width: 40, height: 15, textAlign: 'center' }} body={(props) => <BtnActionProc {...props} />} />
      </DataTable>
    </Flex>
  )
}
