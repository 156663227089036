import { Button, Card, ChatMessages, Flex, Writer, Modal, Text } from '../../../Components'
import { FC, invalidateQuery, useCQuery } from '../../../Services'
import { createNewProcess, getCorrectState } from './Functions'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../Provider/AuthProvider'
import { ModalSegnalazione } from './ModalSegnalazione'
import { ModalEscludiNora } from './ModalEscludiNora'

export const FormChat = ({ stateRef }) => {
  const { conversationId } = useParams()
  const { data: conversation = {}, isSuccess } = useCQuery(['conversation', conversationId])
  const [messages, setMessages] = useState([])
  const { user: { user = {} } = {} } = useAuth()
  const navigate = useNavigate()
  const [modalSegnalazione, setModalSegnalazione] = useState(false)
  const [modalEscludiNora, setModalEscludiNora] = useState(false)
  const [callSpinnerEscludiNora, setCallSpinnerEscludiNora] = useState(false)

  useEffect(() => {
    if ((messages.length < conversation?.messages?.length ||
      messages.map(m => m.attachments.length) < conversation?.messages?.map(m => m.attachments.length)) &&
       isSuccess) {
      const travellerEmail = conversation?.traveller?.email
      setMessages(conversation?.messages?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .map(({ message, sender, createdAt, attachments }) => ({ message, user: sender === 'traveller' ? travellerEmail : 'Nora', createdAt: new Date(createdAt), attachments })))
    }
  }, [conversation, isSuccess])

  const sendMessage = async (message) => {
    setMessages([...messages, { message, user: 'Nora', createdAt: new Date(), attachments: [] }])
    const newState = { ...getCorrectState(stateRef.current), operatorInfo: { messageToSend: message } }
    await createNewProcess(newState, conversationId, 'sendOperatorMessageFromForm')
    await FC.client.service('conversations').patch(conversationId, { status: 'open' })
    invalidateQuery(['conversation', 'conversations'])
  }

  /**
 * Esclude "Nora" dalla conversazione corrente.
 * Questa funzione aggiorna lo stato della conversazione per escludere "Nora",
 * avvia un nuovo processo per chiudere la conversazione con "Nora" esclusa,
 * e mostra una notifica di successo all'utente.
 * Inoltre, invalida le query relative alle conversazioni per garantire che i dati
 * siano aggiornati e nasconde il modal associato.
 *
 * @author mranocchia
 */
  const excludeNoraFromConversation = async () => {
    setCallSpinnerEscludiNora(true)
    const newState = { ...getCorrectState(stateRef.current), noraIsIncluded: false }
    await createNewProcess(newState, conversationId, 'closeConversationExcludeNora')
    window.growl.show({ severity: 'success', summary: 'Esclusione nora', detail: 'Operazione avvenuta con successo' })
    invalidateQuery(['conversation', 'conversations'])
    setModalEscludiNora(false)
    setCallSpinnerEscludiNora(false)
  }

  const createTest = async () => {
    try {
      const res = await FC.service('autoTests').create({
        messages: [{ user: 'User', message: messages[0]?.message }],
        correctState: conversation?.lastProcessStep?.finalState
      })
      if (res) return window.growl.show({ severity: 'success', summary: 'Test creato', detail: 'Il test è stato creato con successo' })
    } catch (e) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Si è verificato un errore durante la creazione del test' })
    }
  }

  const AdminButtons = () => (
    <>
      <Button label='Vai a processi' icon='settings' onClick={() => navigate('/conversations/' + conversationId)} style={{ marginLeft: 10 }} />
      <Button label='Crea Test' icon='extreme' onClick={createTest} style={{ marginLeft: 10 }} />
    </>
  )
  const Segnalazione = () => (
    <>
      <Button tooltip='Invia Segnalazione' round icon='fa-duotone fa-flag' onClick={() => setModalSegnalazione(true)} style={{ marginLeft: 10 }} />
      <Modal visible={modalSegnalazione} header='Invia Segnalazione' onHide={() => setModalSegnalazione(false)} style={{ width: '80%' }}>
        <ModalSegnalazione onHide={() => setModalSegnalazione(false)} />
      </Modal>
    </>
  )
  const EscludiNora = () => (
    <>
      <Button tooltip='Escludi Nora' round icon='fa-duotone fa-hand' onClick={() => setModalEscludiNora(true)} style={{ marginLeft: 10, backgroundColor: 'red' }} />
      <Modal visible={modalEscludiNora} header='Escludi Nora' onHide={() => setModalEscludiNora(false)} style={{ width: '50%' }}>
        <ModalEscludiNora callSpinnerEscludiNora={callSpinnerEscludiNora} onConfirm={excludeNoraFromConversation} onHide={() => setModalEscludiNora(false)} />
      </Modal>
    </>
  )

  if (!isSuccess) return null
  return (
    <Flex fw>
      <Flex fw je row style={{ height: 30 }}>
        <Button label='Torna indietro' icon='left' onClick={() => navigate('/operatore/list')} />
        {user?.role === 'admin' && <AdminButtons />} {/* admin block */}
        {!!user.environment.enableReport && <Segnalazione />} {/* segnalazione block */}
        {(conversation.noraIsIncluded && !stateRef?.current?.errorDescriptionToOperator) && <EscludiNora />} {/* bottone e modal di esclusione di Nora */}
      </Flex>
      <Card style={{ padding: 10, borderRadius: 10, marginTop: 10, overflow: 'scroll', width: '100%' }}>
        {!!conversation?.subjectMidoffice &&
          <Text value={conversation.subjectMidoffice} style={{ width: '100%', textAlign: 'center', fontWeight: 'bold' }} />}
        <ChatMessages messages={messages} jsonVisualized={false} />
        {conversation?.status === 'handledByOperator' &&
          <Writer sendMessage={sendMessage} composerStyle={{ width: '100%', padding: 0 }} noAttachments noraDraft={stateRef.current.draftMessage} />}
      </Card>
    </Flex>
  )
}
